import React from 'react';
import { NavigationBar } from './navigationBar';
import { Modals } from './modal';
import { Footer } from './footer';
import { ScriptCDN } from './scriptCDN';
import { resources } from './locales.js'

export function ContactContent({ lang }) {
    return (
        <div>
            <NavigationBar lang={lang}></NavigationBar>

            <div class="container" >
                <div class="row ">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 text-center text-zer-urdin">
                        <h1>{resources[lang]["contact"]["message"]}<a href="http://vicomtech.org" target="_blank">vicomtech.org</a></h1>
                    </div>
                </div>
                <div class="row  justify-content-left">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 pt-5 ">
                        <form action="https://formspree.io/ttprodkudeaketa01@gmail.com" method="POST" >
                            <div class="form-group">
                                <label for="nombre" class="form-text text-muted">{resources[lang]["contact"]["name"]["tag"]}</label>
                                <input type="text" class="form-control" name="nombre" id="nombre" placeholder={resources[lang]["contact"]["name"]["text"]}/>
                            </div>
                            <div class="form-group">
                                <label for="correo" class="form-text text-muted">{resources[lang]["contact"]["mail"]["tag"]}</label>
                                <input type="email" class="form-control" name="_replyto" id="correo" placeholder={resources[lang]["contact"]["mail"]["text"]}/>
                                <input type="text" name="_gotcha" style={{"display":"none"}} />
                                <input type="hidden" name="_next" value="/kontaktua#eskerrikAsko"/>
                            </div>
                                <div class="form-group">
                                <label for="consulta" class="form-text text-muted">{resources[lang]["contact"]["comment"]["tag"]}</label>
                                <textarea class="form-control" name="consulta" id="consulta" rows="4" placeholder={resources[lang]["contact"]["comment"]["text"]}></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary button-batua button-batua-block">{resources[lang]["contact"]["send"]}</button>
                        </form>
                    </div>
                </div>
            </div>

            <Modals lang={lang}></Modals>
            <Footer lang={lang}></Footer>
            <ScriptCDN lang={lang} page="contact"></ScriptCDN>
        </div>
    );
}
